// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import {  useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import Seo, { SeoData } from "../components/Seo/Seo"
import HeroMedia, { HeroMediaData } from "../components/HeroMedia";
import MediaGallery, { MediaGalleryData } from "../components/MediaGallery";
import MediaInquiries, { MediaInquiriesData } from "../components/MediaInquiries"; 
import { useSiteMetadata } from "../hooks/use-site-metadata";

interface PageData {
  media: {
    mediaSeo: SeoData
    mediaMarquee: HeroMediaData
    mediaItems: MediaGalleryData[]
    mediaContact: MediaInquiriesData
  }
}

interface Props extends PageProps<PageData> {}

const AboutPage: React.FC<Props> = ({ data }) => {
  const {
    mediaSeo,
    mediaMarquee,
    mediaItems,
    mediaContact,
  } = data.media

  const { siteDomain } = useSiteMetadata()
  const { language } = useI18next();

  const mediaUrl = `https://${siteDomain}/${language}/media/`

  useEffect(() => {
    if (window !== undefined) {
      require("lazysizes/plugins/attrchange/ls.attrchange.js");
      require("lazysizes/plugins/bgset/ls.bgset");
      require("lazysizes");
    }
  }, []);

  return (
    <Layout>
      <Seo data={mediaSeo} url={mediaUrl} locale={language} />

      <HeroMedia data={mediaMarquee} />

      <MediaGallery
        clipPath_lg="polygon(
          0% 0%,
          100% 0%,
          100% calc(100% - 28px),
          69.86% calc(100% - 90px),
          71.45% calc(100% - 6px),
          0% calc(100% - 148px)
        )"
        clipPath_md="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100%), 
          13.28% calc(100% - 90px), 
          15.88% calc(100% - 6px), 
          0% calc(100% - 14px)
        )"
        clipPath_sm="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100% - 27px), 
          82.5% calc(100% - 38px), 
          83.84% calc(100% - 2px), 
          0% calc(100% - 38px)
        )"
        data={[
          ...mediaItems,
        ]}
      />

      <MediaInquiries data={mediaContact} />

    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    media: contentstackForge20MediaPage(locale: { eq: $language }) {
      ...MediaSeoFragment
      ...MediaMarqueeFragment
      ...MediaMarqueeFragment
      ...MediaItemsFragment
      ...MediaContactFragment
    }
    ...TranslationQueryFragment
  }
`;

