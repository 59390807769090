import React from "react"
import { graphql } from "gatsby";
import HeroType2 from "../HeroType2"

import HeroDesktop from "../../images/media/media-lg.png";
import Herolaptop from "../../images/media/media-md.png";
import HeroTablet from "../../images/media/media-sm.png";
import HeroMobile from "../../images/media/media-xs.png";

export const fragment = graphql`
  fragment MediaMarqueeFragment on Contentstack_forge_2_0_media_page {
    mediaMarquee: media_marquee_section {
      headline
      flavorCopy: flavor_copy
      descriptionCopy: description_copy
      legalCopy: legal_copy
      marqueeCta: marquee_cta {
        title
        href
      }
      youtubeEmbedLink: youtube_embed_link {
        title
        href
      }
      videoThumb: video_thumb {
        url
      }
      inlineVideoFile: inline_video_file {
        url
      }
      videoDescriptor: video_descriptor
    }
  }
`

export type HeroMediaData = {
  headline: string
  flavorCopy: string
  descriptionCopy: string
  legalCopy: string
  marqueeCta: {
    title: string
    href: string
  }
  youtubeEmbedLink: {
    title: string
    href: string
  }
  videoThumb: {
    url: string
  }
  inlineVideoFile: {
    url: string
  }
  videoDescriptor: string
}

interface Props {
  data: HeroMediaData
}

const HeroMedia = (props: Props) => {
  const {
    headline,
    flavorCopy,
    descriptionCopy,
    legalCopy,
    marqueeCta,
    youtubeEmbedLink,
    videoThumb,
    inlineVideoFile,
    videoDescriptor,
  } = props.data

  return (
    <HeroType2
      title={headline}
      subTitle={flavorCopy}
      description={descriptionCopy}
      videoTitle={youtubeEmbedLink.title}
      text_color="mushroom"
      cta_text="download all"
      cta_type="tertiary"
      cta_subtext=""
      video={inlineVideoFile.url}
      iframeLink={youtubeEmbedLink.href}
      mobileBG={HeroMobile}
      tabletBG={HeroTablet}
      laptopBG={Herolaptop}
      desktopBG={HeroDesktop}
      clipPath_lg="polygon(
        0% 0%,
        100% 0%,
        100% calc(100% - 124px),
        42.02729% calc(100% - 8px),
        43% calc(100% - 92px),
        0% 100%
      )"
      clipPath_md="polygon(
        0% 0%, 
        100% 0%, 
        100% calc(100% - 72px), 
        35.02% calc(100% - 8px), 
        37.36% calc(100% - 88px), 
        0% calc(100% - 51px)
      )"
      clipPath_sm="polygon(
        0% 0%, 
        100% 0%, 
        100% calc(100% - 42px), 
        8.75% calc(100% - 2px), 
        10.62% calc(100% - 36px), 
        0% calc(100% - 30px)
      )"
      bg_color="#230D25"
    />
  )
}

export default HeroMedia
