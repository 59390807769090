import React, { useState, useEffect } from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import Select from "react-select";
import { SelectStyles } from "../Ui/Select";
import { Container, Row, Col } from "react-awesome-styled-grid";
import bg from "../../images/media/gallery_bg.png";

import {
  CloseIcon,
  NextIcon,
  PrevIcon,
  TitleIcon,
  Section,
  FilterArea,
  TabNavigation,
  TabNavigationSelect,
  TabUl,
  TabLi,
  TabBtn,
  Gallery,
  GalleryItemBox,
  GalleryItem,
  ItemTitle,
  PopupView,
  PopupViewWrapper,
  PopupClose,
  PopupLayer,
  PopupDownload,
  PopupDownloadBtn,
  PopupDownloadSize,
  PopupNav,
  PopupNavBtn,
  PopupNavTitle,
} from "./style";

import Paragraph from "../Commons/Paragraph";
import Button from "../Commons/Button";

const friendlySize = (bytes: string) => {
  let size: number = +bytes / 1024;
  let unit = "KB";
  if (size >= 1024) {
    size /= 1024;
    unit = "MB";
  }
  if (size >= 1024) {
    size /= 1024;
    unit = "GB";
  }
  let sizeString = `${size.toFixed(2)}`;
  sizeString = sizeString.replace(/0+$/, "");
  sizeString = sizeString.replace(/\.$/, "");
  return `${sizeString}${unit}`;
};

export const fragment = graphql`
  fragment MediaItemsFragment on Contentstack_forge_2_0_media_page {
    mediaItems: media_items {
      title
      mediaCategory: media_category {
        key
        title
      }
      gameTitle: game_title {
        title
      }
      mediaItemInfo: media_item_info {
        mediaThumbnail: media_thumbnail {
          url
        }
        mediaAsset: media_asset {
          url
        }
        downloadable
        downloadableAsset: downloadable_asset {
          url
          fileSize: file_size
        }
      }
    }
  }
`;

export interface MediaGalleryData {
  title: string;
  mediaCategory: [
    {
      key: string;
      title: string;
    },
  ];
  gameTitle: [
    {
      title: string;
    },
  ];
  mediaItemInfo: {
    mediaThumbnail: {
      url: string;
    };
    mediaAsset: {
      url: string;
    };
    downloadable: boolean;
    downloadableAsset?: {
      url: string;
      fileSize: string;
    };
  };
}

type Tab = {
  tabName: string;
  items: Item[];
};

type ItemFilter = {
  slug: string;
  name: string;
};
type Item = {
  image: string;
  title: string;
  dataSize: number;
  filter: ItemFilter;
};

type MadiaType = {
  data: MediaGalleryData[];
  clipPath_lg: string;
  clipPath_md: string;
  clipPath_sm: string;
};

type ItemType = {
  isOpen: boolean;
  item: any;
  index: number;
};

export default function MediaGallery(props: MadiaType) {
  const { data } = props;

  const categories = data.flatMap((item) => item.mediaCategory);
  const tabKeys = _.uniqBy(categories, (category) => category.key);
  const tabData = tabKeys.map((tab) => {
    const filtered = data.filter((item) => item.mediaCategory.map((category) => category.key).includes(tab.key));
    return {
      tabName: tab.title,
      items: filtered.map((item) => ({
        thumb: item.mediaItemInfo.mediaThumbnail.url,
        image: item.mediaItemInfo.mediaAsset.url,
        title: item.title,
        dataSize: Number(item.mediaItemInfo.downloadableAsset?.fileSize ?? 0),
        downloadURL: item.mediaItemInfo.downloadableAsset?.url ?? "",
        downloadable: item.mediaItemInfo.downloadable,
        filter: {
          slug: item.gameTitle[0].title,
          name: item.gameTitle[0].title,
        },
      })),
    };
  });

  const [activeTab, setActiveTab] = useState(_.get(tabData[0], "tabName", "all"));
  const [allItems, setAllItems] = useState<Item[]>([]);

  const [list, setList] = useState<Tab[]>([]);
  const [games, setGames] = useState<ItemFilter[]>([]);
  const [filteredGames, setFilteredGames] = useState<ItemFilter | null>({
    slug: "all",
    name: "All",
  });

  useEffect(() => {
    if (data && !!data.length) {
      const copyData = JSON.parse(JSON.stringify(tabData));
      let filters: ItemFilter[] = [
        {
          slug: "all",
          name: "All",
        },
      ];
      let items: Item[] = [];

      copyData.forEach((tab: Tab) => {
        tab?.items &&
          !!tab?.items?.length &&
          tab?.items.forEach((item: Item) => {
            items.push(item);
            if (item?.filter && !filters.find((filter_item) => filter_item.slug === item?.filter?.slug)) {
              filters.push(item.filter);
            }
          });
      });

      const parsedData: Tab[] = [
        {
          tabName: "all",
          items,
        },
        ...copyData,
      ];

      setList([...parsedData]);
      setAllItems(items);
      setGames(filters);
    }
  }, []);

  useEffect(() => {
    if (list && !!list.length) {
      if (activeTab === "all") {
        const parsedData = list.map((listItem) => {
          if (listItem.tabName === "all") {
            listItem.items =
              filteredGames && filteredGames?.slug !== "all"
                ? allItems.filter((item) => item.filter.slug === filteredGames.slug)
                : allItems;
          }
          return listItem;
        });
        setList(parsedData);
      } else {
        const parsedData = list.map((listItem) => {
          if (listItem.tabName === activeTab) {
            const copyData = tabData?.slice();
            const findedData: Tab | undefined = copyData.find((tab) => tab.tabName === activeTab);

            if (filteredGames && filteredGames?.slug !== "all") {
              const filteredData = findedData?.items.filter((item) => item.filter.slug === filteredGames.slug) ?? [];
              listItem.items = filteredData;
            } else {
              listItem.items = findedData?.items ?? [];
            }
          }
          return listItem;
        });
        setList(parsedData);
      }
    }
  }, [filteredGames, activeTab]);

  const [activeItem, setActiveItem] = useState<ItemType>({
    isOpen: false,
    item: null,
    index: 0,
  });

  const nextImage = () => {
    let nextIndex = activeItem.index + 1;
    let findedData: Tab | undefined = list.find((tab) => tab.tabName === activeTab);
    if (findedData) {
      nextIndex = nextIndex % findedData?.items?.length;
      setActiveItem({
        isOpen: true,
        item: findedData?.items[nextIndex],
        index: nextIndex,
      });
    }
  };

  const prevImage = () => {
    let prevIndex = activeItem.index;
    let findedData: Tab | undefined = list.find((tab) => tab.tabName === activeTab);
    if (findedData) {
      if (prevIndex === 0) {
        prevIndex = findedData?.items?.length;
      }
      prevIndex = prevIndex - 1;
      setActiveItem({
        isOpen: true,
        item: findedData?.items[prevIndex],
        index: prevIndex,
      });
    }
  };

  const handleKeyPress = (e: { keyCode: number }) => {
    if (activeItem && activeItem.isOpen) {
      if (e.keyCode === 39) {
        nextImage();
      } else if (e.keyCode === 37) {
        prevImage();
      } else if (e.keyCode === 27) {
        setActiveItem({ isOpen: false, item: null, index: 0 });
      }
    }
  };

  return (
    <Section
      onKeyDown={handleKeyPress}
      tabIndex={-1}
      // className="lazyload"
      data-bgset={`${bg} [(min-width: 1px)]`}
      image={bg}
      clipPath_lg={props.clipPath_lg}
      clipPath_md={props.clipPath_md}
      clipPath_sm={props.clipPath_sm}
      style={{ clipPath: activeItem && activeItem.isOpen ? "none" : "" }}
    >
      <Container>
        <Row justify="flex-start">
          <Col xs={2} sm={2} md={12} lg={12} style={{ position: "relative", zIndex: 9 }}>
            <FilterArea>
              <Select
                placeholder="Choose Your Game"
                options={games}
                value={filteredGames}
                getOptionLabel={(option) => option.name}
                etOptionValue={(option: any) => option.slug}
                styles={SelectStyles(false)}
                onChange={setFilteredGames}
              />
            </FilterArea>
            {/* This className was causing a stacking order bug in Safari
            <TabNavigationSelect className=" animation-opacity"> */}
            <TabNavigationSelect>
              <Select
                placeholder="Media Category"
                options={tabData}
                getOptionLabel={(option) => option.tabName}
                etOptionValue={(option: any) => option.tabName}
                styles={SelectStyles(false)}
                onChange={(tab) => setActiveTab(tab?.tabName ?? "all")}
              />
            </TabNavigationSelect>

            {/* This className was causing a stacking order bug in Safari
            <TabNavigation className=" animation-opacity"> */}
            <TabNavigation>
              <TabUl>
                {list &&
                  !!list.length &&
                  list.map((tab: { tabName: string }, index: number) => {
                    if (tab?.tabName !== "all") {
                      return (
                        <TabLi
                          className={tab?.tabName === activeTab ? "active" : ""}
                          key={index}
                          onClick={() => setActiveTab(tab?.tabName)}
                        >
                          <TabBtn>{tab?.tabName}</TabBtn>
                        </TabLi>
                      );
                    }
                  })}
              </TabUl>
            </TabNavigation>
          </Col>

          <Col xs={2} sm={2} md={12} lg={12}>
            <Gallery>
              <Row>
                {list &&
                  !!list.length &&
                  list.map((col) => {
                    if (col?.tabName === activeTab) {
                      return col.items.map((item: { image: string; title: string }, index: number) => (
                        <Col xs={2} sm={2} md={4} lg={4}>
                          <GalleryItem key={index}>
                            <GalleryItemBox
                              image={item?.image}
                              onClick={() =>
                                setActiveItem({
                                  isOpen: true,
                                  item: item,
                                  index: index,
                                })
                              }
                            ></GalleryItemBox>
                            <ItemTitle>
                              <TitleIcon />
                              {/* <h1>{item?.title}</h1> */}
                            </ItemTitle>
                          </GalleryItem>
                        </Col>
                      ));
                    }
                  })}
              </Row>
            </Gallery>
          </Col>
          <Col xs={2} sm={2} md={12} lg={12} align="center" style={{ marginTop: "48px" }}>
            {/*
            <Button className="animation-up" size="medium" type="secondary">
              Load More
            </Button>
            */}
          </Col>
        </Row>
      </Container>
      {activeItem && activeItem.isOpen && (
        <>
          <PopupView>
            <PopupViewWrapper ie_image={activeItem?.item?.image}>
              <img className="lazyload noselect" data-src={activeItem.item?.image} />
            </PopupViewWrapper>
            <PopupClose>
              <Button
                size="medium"
                type="secondary"
                onClick={() => setActiveItem({ isOpen: false, item: null, index: 0 })}
              >
                <CloseIcon />
              </Button>
            </PopupClose>
            <PopupNav>
              <PopupNavBtn>
                <Button size="medium" type="secondary" onClick={() => prevImage()}>
                  <PrevIcon />
                </Button>
                <Button size="medium" type="secondary" onClick={() => nextImage()}>
                  <NextIcon />
                </Button>
              </PopupNavBtn>
              <PopupNavTitle>
                <TitleIcon />
                {/* {activeItem.item?.title} */}
              </PopupNavTitle>
              {activeItem.item?.downloadable && (
                <PopupDownload>
                  <PopupDownloadSize>
                    {/*Asset type: ZIP // */}File size: {friendlySize(activeItem.item?.dataSize)}
                  </PopupDownloadSize>
                  <PopupDownloadBtn>Download</PopupDownloadBtn>
                </PopupDownload>
              )}
            </PopupNav>
          </PopupView>
          <PopupLayer onClick={() => setActiveItem({ isOpen: false, item: null, index: 0 })} />
        </>
      )}
    </Section>
  );
}
