import React from "react";
import styled, { css } from "styled-components";
import bg from "../../images/media/gallery_bg.png";

export const TitleIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6649 21.3864L4 28.4492V38.114H43.7746V24.3602L32.2511 14.6953L19.9842 28.0774L13.6649 21.3864Z"
        fill="white"
        stroke="white"
        strokeWidth="0.439024"
      />
      <rect x="4.87805" y="8.87805" width="38.3902" height="28.7254" stroke="white" strokeWidth="1.7561" />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.3554 5.6564L37.7052 5.30286L37.3536 4.95117L34.0488 1.64645L33.6953 1.29289L33.3417 1.64645L19.5732 15.4149L5.6564 1.64458L5.30286 1.29476L4.95117 1.64645L1.64645 4.95117L1.29476 5.30286L1.64458 5.6564L15.4149 19.5732L1.64645 33.3417L1.29289 33.6953L1.64645 34.0488L4.95117 37.3536L5.30286 37.7052L5.6564 37.3554L19.5732 23.5851L33.3417 37.3536L33.6953 37.7071L34.0488 37.3536L37.3536 34.0488L37.7071 33.6953L37.3536 33.3417L23.5851 19.5732L37.3554 5.6564Z"
        fill="#30353F"
        stroke="#30353F"
      />
    </svg>
  );
};

export const NextIcon = () => {
  return (
    <svg width="22" height="42" viewBox="0 0 22 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 21L0.399998 0.215384L0.399996 41.7846L22 21Z" fill="#30353F" />
    </svg>
  );
};

export const PrevIcon = () => {
  return (
    <svg width="22" height="42" viewBox="0 0 22 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-1.04907e-06 21L21.6 0.215384L21.6 41.7846L-1.04907e-06 21Z" fill="#30353F" />
    </svg>
  );
};

export const FilterArea = styled.div`
  margin: 10px 0;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin: 10px 0 30px;
  }
`;

export const TabNavigation = styled.nav`
  display: none;
  background-color: ${(props) => props.theme.variables.primary.white};
  margin-bottom: 30px;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: block;
  }
`;
export const TabNavigationSelect = styled.div`
  display: block;
  background-color: ${(props) => props.theme.variables.primary.white};

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: none;
  }
`;
export const TabUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
`;
export const TabLi = styled.li`
  padding: 18px 0px;
  width: 100%;
  text-align: center;
  box-shadow: inset 0px -4px 0px -1px ${(props) => props.theme.variables.secondary.cream};
  &.active {
    box-shadow: inset 0px -4px 0px -1px ${(props) => props.theme.variables.primary.black};
  }
`;

export const TabBtn = styled.a`
  font-style: normal;
  font-weight: 700;
  font-size: 20.6839px;
  line-height: 23px;
  color: #32281e;
  cursor: pointer;
`;

export const Gallery = styled.div`
  margin-top: 20px;
  overflow: hidden;
`;

type GalleryItemBoxType = {
  image: string;
};

export const GalleryItemBox = styled.div<GalleryItemBoxType>`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;

  background-size: cover;
  background-position: 50% 50%;
  transition: transform 0.3s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const GalleryItem = styled.div`
  display: block;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;

  :hover ${GalleryItemBox} {
    transition-duration: 0.1s;
    transform: scale(1.05);
  }
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 18px;
  background-color: ${(props) => props.theme.variables.primary.black};
  position: absolute;
  bottom: 0;
  right: 0;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: ${(props) => props.theme.variables.primary.white};
  }
  svg {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
`;

export const PopupView = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-self: flex-start;
  z-index: 10;
  transform: translateZ(0.1px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

type IeImage = {
  ie_image?: string
}

export const PopupViewWrapper = styled.div<IeImage>`
  position: relative;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-image: url(${(props) => props.ie_image});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
    }
  }
`

export const PopupNav = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  background: rgb(64 64 64 / 80%);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    background: #404040;
  }
`;

export const PopupNavBtn = styled.div`
  display: flex;
  align-items: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding: 8px 8px;
      line-height: 1;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  button:last-child {
    margin-left: 24px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 12px 12px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;

export const PopupNavTitle = styled.h1`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 0px;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 16px 0px;
  color: ${(props) => props.theme.variables.primary.white};
  svg {
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin: 24px 0px;
    font-size: 24px;
    line-height: 28px;
    svg {
      width: 48px;
      height: 48px;
    }
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-left: 80px;
  }
`;

export const PopupDownload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    flex-direction: row;
  }
`;

export const PopupDownloadBtn = styled.button`
  background: none;
  color: white;
  border: none;
  outline: none;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const PopupDownloadSize = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.variables.secondary.mushroom};
  margin-right: 0px;
  margin-bottom: 8px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: 16px;
    line-height: 32px;
    margin-right: 64px;
    margin-bottom: 0px;
    text-align: left;
  }
`;

export const PopupClose = styled.div`
  position: absolute;
  right: 8px;
  top: 16px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding: 12px 14px;
      line-height: 0;
    }
  }
`;

export const PopupLayer = styled.div`
  position: fixed;
  z-index: 9;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.9);
  transform: translateZ(0.1px);
`;

type PROPS_TYPE = {
  clipPath_lg?: any;
  clipPath_md?: any;
  clipPath_sm?: any;
  image: string;
};

export const Section = styled.section<PROPS_TYPE>`
  //pointer-events: none;
  background: white;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.variables.primary.white};
  clip-path: ${(props) => props.clipPath_sm};
  padding: 0px 0px 92px 0px;

  &:focus-visible {
    outline: none !important;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    clip-path: ${(props) => props.clipPath_md};
    padding: 0px 0px 180px 0px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    clip-path: ${(props) => props.clipPath_lg};
    padding: 168px 0px 220px 0px;
  }

  @media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    background-image: url(${(props) => props.image});
  }
`;
