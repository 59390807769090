import React from "react"
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-awesome-styled-grid"
import styled from "styled-components"
import Button from "../Commons/Button"
import Title from "../Commons/Title"
// import "lazysizes"
// import "lazysizes/plugins/attrchange/ls.attrchange"
// import "lazysizes/plugins/bgset/ls.bgset"

import MediaInquiriesDesktop from "../../images/MediaInquiries/desktop.jpg";
import MediaInquirieslaptop from "../../images/MediaInquiries/laptop.jpg";
import MediaInquiriesTablet from "../../images/MediaInquiries/tablet.jpg";
import MediaInquiriesMobile from "../../images/MediaInquiries/mobile.jpg";

type PROPS_TYPE = {
  mobileBG: string
  desktopBG: string
  laptopBG: string
  tabletBG: string
  ie_sm: string
  ie_md: string
  ie_lg: string
  ie_xl: string
}

const Section = styled.section<PROPS_TYPE>`
  padding: 88px 0px 88px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #213247;
  margin-top: -39px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 210px 0px 46px 0px;
    margin-top: -91px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 388px 0 298px 0;
    margin-top: -149px;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    background-image: url(${(props) => props.ie_sm});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    background-image: url(${(props) => props.ie_md});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    background-image: url(${(props) => props.ie_lg});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-image: url(${(props) => props.ie_xl});
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    align-items: center;
    text-align: center;
  }
`

const DescriptionWrapper = styled.div`
  margin-top: 24px;
`

type ParagraphType = {
  color: string
}

const Paragraph = styled.p<ParagraphType>`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  max-width: 483px;
  text-align: left;
  color: ${props =>
    props.theme.variables.secondary[props?.color]
      ? props.theme.variables.secondary[props?.color]
      : props.theme.variables.secondary.mushroom};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 48px;
  }
`

export const fragment = graphql`
  fragment MediaContactFragment on Contentstack_forge_2_0_media_page {
    mediaContact: media_page_contact_section {
      alignment
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
    }
  }
`

export type MediaInquiriesData = {
  alignment: string
  headline: string
  bodyCopy: string
  ctaLink: {
    title: string
    href: string
  }
}

type MediaInquiriesType = {
  data: MediaInquiriesData
}

const MediaInquiries = (props: MediaInquiriesType) => {
  const {
    alignment,
    headline,
    bodyCopy,
    ctaLink,
  } = props.data

  return (
    <Section
      mobileBG={MediaInquiriesMobile}
      tabletBG={MediaInquiriesTablet}
      laptopBG={MediaInquirieslaptop}
      desktopBG={MediaInquiriesDesktop}
      className="lazyload"
      ie_sm={MediaInquiriesMobile}
      ie_md={MediaInquiriesTablet}
      ie_lg={MediaInquirieslaptop}
      ie_xl={MediaInquiriesDesktop}
      data-bgset={` ${MediaInquiriesDesktop} [(min-width: 1440px)] | ${MediaInquirieslaptop} [(min-width: 768px)] | ${MediaInquiriesTablet} [(min-width: 320px)] | ${MediaInquiriesMobile}`}
    >
      <Container>
        <Row align="center" justify="center">
          <Col xs={2} sm={6} md={7} lg={7}>
            <Wrapper className="animation-up-gallery">
              <Title
                className="animation-up"
                level={1}
                color="cream"
                uppercase
              >
                {headline}
              </Title>
              <DescriptionWrapper>
                <Paragraph color="cream">{bodyCopy}</Paragraph>
              </DescriptionWrapper>
            </Wrapper>
            <ButtonWrapper>
              <Button size="large" href={ctaLink.href} as="a" type="tertiary">
                {ctaLink.title}
              </Button>
            </ButtonWrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default MediaInquiries
